import React from 'react';
import { Box, Typography } from '@mui/material';

export default function PushNotification({ notification }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '8px'
      }}>
      <Typography
        style={{
          color: '#474747',
          fontSize: '14px',
          fontWeight: 600
        }}>
        {notification?.title}
      </Typography>
      <Typography style={{ color: '#474747', fontSize: '14px' }}>{notification?.body}</Typography>
    </Box>
  );
}
