import React, { Suspense, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Box } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/styles';
import 'react-toastify/dist/ReactToastify.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Loader from 'components/Loader';
import PushNotification from 'components/PushNotification';
import Sidebar from 'components/Sidebar';
import ErrorBoundary from 'features/Errors/ErrorBoundary';
import { onMessage } from 'firebase/messaging';
import { toast, ToastContainer } from 'react-toastify';
import Protected from 'utils/ProtectedRoutes';
import { messaging } from './firebaseUtils';

const NotFound = React.lazy(() => import('features/Errors/NotFound'));
const Login = React.lazy(() => import('features/Login'));

const Dashboard = React.lazy(() => import('features/Dashboard'));
const AddClient = React.lazy(() => import('features/Client/AddClient'));
const ClientListing = React.lazy(() => import('features/Client/ClientListing'));
const ClientDetails = React.lazy(() => import('features/Client/ClientDetails'));
const PropertyListing = React.lazy(() => import('features/Property/PropertyListing'));
const PropertyDetails = React.lazy(() => import('features/Property/PropertyDetails'));
const AIRAListing = React.lazy(() => import('features/AIRA/AIRAListing'));
const AIRADetails = React.lazy(() => import('features/AIRA/AIRADetails'));
const AIRADashboard = React.lazy(() => import('features/AIRA/AIRADashboard'));
const PropertyVerification = React.lazy(() =>
  import('features/Property/PropertyVerification/PropertyVerification')
);

export default function App() {
  const theme = createTheme({
    typography: {
      fontFamily: 'Lato'
    }
  });

  const isLoggedIn = useSelector((state) => state.auth?.isLoggedIn);
  const [isError, setIsError] = useState(false);

  const handleSetError = (err) => setIsError(err);

  onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);

    const audio = new Audio('../notification-bell.wav');
    audio.play();

    toast.info(<PushNotification notification={payload.notification} />, {
      autoClose: false,
      closeOnClick: false,
      draggable: false,
      hideProgressBar: false,
      icon: () => (
        <img style={{ height: '20px', width: '20px' }} src="./rehub-fav-icon.png" alt="icon" />
      ),
      onClose: () => toast.done(),
      pauseOnHover: false,
      position: 'top-right',
      progress: undefined,
      style: { lineHeight: 1.4 },
      theme: 'light'
    });
  });

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          backgroundColor: '#fff'
        }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {isLoggedIn && !isError && <Sidebar />}
          <Box sx={{ minHeight: '100vh', width: '100%' }}>
            <ErrorBoundary handleSetError={handleSetError}>
              <Routes>
                <Route
                  path="/"
                  // element={isLoggedIn ? <Navigate to="/dashboard" /> : <Navigate to="/login" />
                  element={isLoggedIn ? <Navigate to="/users" /> : <Navigate to="/login" />}
                />
                <Route
                  path={'/login'}
                  element={
                    <Suspense fallback={<Loader />}>
                      {/* {isLoggedIn ? <Navigate to="/dashboard" /> : <Login />} */}
                      {isLoggedIn ? <Navigate to="/users" /> : <Login />}
                    </Suspense>
                  }
                />

                <Route
                  path="/dashboard"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <Dashboard />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/users"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <ClientListing />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/add-user"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddClient />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/user-details/:id"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <ClientDetails />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/edit-user/:id"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AddClient />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/properties"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <PropertyListing />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/property-details/:clientId/:propertyId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <PropertyDetails />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/property-verification/:clientId/:propertyId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <PropertyVerification />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/aira-chats"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AIRAListing />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/aira-chat/:clientId/:chatId"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AIRADetails />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route
                  path="/aira-dashboard"
                  element={
                    <Suspense fallback={<Loader />}>
                      <Protected>
                        <AIRADashboard />
                      </Protected>
                    </Suspense>
                  }
                />

                <Route path="*" element={<NotFound />} />
              </Routes>
            </ErrorBoundary>
            <ToastContainer autoClose={2000} hideProgressBar={false} closeOnClick />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
